<template>
	<div>
		<HeaderPage/><br/><br/>
		<section class="faq">
        <div class="container" data-aos="fade-up">
        <div class="section-title">
          <h2>Vilsons CFS</h2>
          <h4>Tariff Details</h4>
        </div>
        <section id="counts" class="counts">
      <div class="container" data-aos="fade-up">

        <div class="row">
            <div class="col-lg-1 col-md-12"></div>
          <div class="col-lg-5 col-md-6">
            <div class="count-box">
              <i class="bi bi-archive-fill"></i>
              <span data-purecounter-start="0" data-purecounter-end="232" data-purecounter-duration="1" class="purecounter"></span>
              <div class="cmp_head">
                <a href="assets/tariff/VilsonsImportTariff.pdf" target="_blank">Import</a>
              </div>
            </div>
          </div>

          <div class="col-lg-5 col-md-6 mt-5 mt-md-0">
              <div class="count-box">
                <i class="bi bi-inboxes-fill"></i>
                <span data-purecounter-start="0" data-purecounter-end="521" data-purecounter-duration="1" class="purecounter"></span>
                <div class="cmp_head">
                <a href="assets/tariff/vilsonscfsexporttariff.pdf" target="_blank">Export</a>
              </div>
              </div>
          </div>
          <div class="col-lg-1 col-md-12"></div>
          </div>
            </div>
        </section>
      </div>
    </section>
	</div>
</template>


<script>
import HeaderPage from '@/components/Header.vue';
export default {
  name: 'cfstariff',
  components: {
    HeaderPage,
  },
}
</script>
<style>

.card {
  background: #fff;
  border-radius: 2px;
  display: inline-block;
  height: 100px;
  margin: 1rem;
  position: relative;
  width: 300px;
}
.card-5 {
  box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
}
</style>